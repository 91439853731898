import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HomeTiles from '@/components/account/home-tiles';
import NormalButton from '@/components/button/normal';
import LoadingIcon from '@/components/icons/loading-icon';
import Layout from '@/components/layout';
import { login } from '@/lib/auth';
import getPagesCommonProps from '@/lib/get-pages-common-props';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { getNavItemsWithType } from '@/middleware/content-service/get-navigation';
import getIncompleteProfileFields from '@/middleware/lib/get-incomplete-profile-fields';
import { wrapper as StoreWrapper } from '@/store';
import { setPageTheme } from '@/store/slices/global';
import {
  selectIsUserLoading,
  selectIsUserLoggedIn,
  selectUserProfile,
} from '@/store/slices/user';
import {
  NavigationEntry,
  NavigationType,
  NavItemEntryWithIcon,
} from '@/types/views/generic';

interface AccountProps {
  navigation: NavigationEntry;
  tiles: NavItemEntryWithIcon[];
}

const Account: FC<AccountProps> = ({ navigation, tiles }) => {
  const variables = useVariables();

  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const isUserLoading = useSelector(selectIsUserLoading);
  const profile = useSelector(selectUserProfile);

  const dispatch = useDispatch();

  const userName = profile?.firstName || '';
  const incompleteProfileData = getIncompleteProfileFields(profile);

  useEffect(() => {
    dispatch(setPageTheme('dark'));
  }, [dispatch]);

  return (
    <>
      <div id="modals" />
      <div id="tooltip" className="relative z-20" />
      <div id="portal-root" />

      <Layout navigation={navigation} isPreviewModeEnabled={false}>
        <div className="content-box">
          {isUserLoading ? (
            <div className="flex justify-center items-center py-56">
              <LoadingIcon className="w-20 h-20" />
            </div>
          ) : (
            <>
              {isUserLoggedIn ? (
                <div className="relative">
                  <h1 className="text-h1 text-pink my-12 md:text-center lg:mt-28 lg:mb-12">
                    {getVariablePlainText(variables[`account-greeting-text`]) ||
                      `Hey`}{' '}
                    {userName}!
                  </h1>

                  <HomeTiles
                    navigation={tiles}
                    incompleteProfileData={incompleteProfileData}
                  />
                </div>
              ) : (
                <div className="text-center py-44">
                  <p>
                    {getVariablePlainText(variables[`account-not-logged-in`]) ||
                      'Du bist nicht eingeloggt, bitte logge dich ein.'}
                  </p>

                  <div className="mt-8">
                    <NormalButton text="Log in" onClick={login} />

                    <NormalButton text="Back to homepage" className="ml-8" />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Layout>
    </>
  );
};

export default Account;

export const getServerSideProps = StoreWrapper.getServerSideProps(
  (store) =>
    async ({ req, res }) => {
      try {
        const { navigation } = await getPagesCommonProps(store);
        const accountNavigation = await getNavItemsWithType(
          NavigationType.AccountNavigation,
        );

        return {
          props: {
            navigation,
            tiles: accountNavigation,
          },
        };
      } catch (error) {
        console.error(error);
        // TODO (ECH-3457): handle error somehow
        throw error;
      }
    },
);
